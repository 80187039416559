import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const PatentIssued = () => {
    const seo = {
        metaDesc: 'Patent Issued - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Patent Issued - Inventiv.org" canonical='/patent-issued' seo={seo} />
            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container">
                    <h1>1. The USPTO has issued your patent.</h1>

                    <p><strong>Table of Contents</strong></p>
                    <p><strong>1.1&nbsp; <a href="#last" class="d-clr">How long does a utility patent last?</a></strong></p>
                    <p><strong>1.2&nbsp; <a href="#expiration" class="d-clr">Patent expiration</a></strong></p>
                    <p><strong>1.3&nbsp; <a href="#extensions" class="d-clr">Extensions to the Patent</a></strong></p>

                    <h2 id="last">1.1 How long does a utility patent last?</h2>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>
                                            Patent life is valid for 20 years. However, there are a few circumstances that can expand the lifespan, such include:
                                            <ul>
                                                <li>Delay in examination by the patent office</li>
                                                <li>Late in amendment reply</li>
                                                <li>If a patent is not issued within four months of paying the issue fee</li>
                                                <li>If USPTO doesn’t issue office action within four months of appeal.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p>In the United States, provided that maintenance fees are timely paid, the life of a utility patent is determined as follows:</p>
                    <ul>
                        <li>For applications filed on or after June 8, 1995, the patent term is 20 years from the filing date of the earliest U.S. application to which priority is claimed.</li>
                        <li>For applications that were pending on and for patents that were still in force on June 8, 1995, the patent term is either 17 years from the issue date or 20 years from the filing date of the earliest U.S. or international (PCT) application to which priority is claimed.</li>
                    </ul>
                    <p>You can extend the life of your patent if you qualify in any of the below circumstances:</p>
                    <ul>
                        <li>The USPTO fails to examine your new application within 14 months after you file.</li>
                        <li>It doesn’t respond in four months to an amendment reply or appellate brief.</li>
                        <li>It doesn’t issue a patent within four months after you’ve already paid the issue fee and submitted drawings.</li>
                        <li>It doesn’t issue an allowance or Office Action within four months after it has made an appellate decision.</li>
                        <li>It doesn’t issue a patent within three years from the filing date. You also haven’t filed a continuation or divisional application or bought a delay for an Office Action reply.</li>
                        <li>Secrecy orders, appeals, or interferences cause the delay.</li>
                    </ul>

                    <h2>1.2 Patent expiration</h2>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>A Patent expires either because its term has come to an end (20 years from earliest priority date), or the maintenance fee due every 4 years have not been timely paid.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <img src="../assets/img/banner/expired-stamp-showing-product-validity-ended_G1-F0bvO-e1602439630717.jpg" width="100%" alt="" />
                    <p>A patent expires either because its term has come to an end or you haven’t paid the maintenance fees.&nbsp; After a patent “expires,” the invention covered by the patent is then in the public domain. That means that anyone can freely use or make the invention without your permission.</p>

                    <h2 id="extensions">1.3 Extensions to the Patent</h2>
                    <p>It is still possible to patent improvements to the original patented item. &nbsp;The drug companies are experts at patent extension strategies. For example, one common way drug companies extend a patent is by reformulation or repositioning a drug. Reformulation is the process of applying different formulations for the same drug, whereas repositioning is the process of finding a new therapeutic use for the drug.&nbsp; Often, new advances are focused on how to simplify dosing or how the drug is administered. Extended-release versions of drugs are common ways companies reformulate products, for example. New methods of administering drugs may mean taking an injectable drug and creating a nasal spray version. Patent extension can be achieved by providing dissolvable tablets that don’t have to be taken with water, as another example. Thus, reformulating a drug so it can be administered in a different form can confer longer patent protection. Repositioning the drug can also extend patent life. For example, the discovery of new uses for the drug can earn the drug company an additional three years of protection under FDA rules.</p>

                </div>
            </section>
        </Layout>
    )
};

export default PatentIssued;